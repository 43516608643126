import React from "react";
import { theme } from "../tailwind.config.js";

export const Beetroot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={100} height={155} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#prefix__clip0_112_80)" strokeWidth={2} strokeLinejoin="round">
      <path
        d="M21.867 153.337c-3.108-10.344-13.76-16.852-17.634-26.935-3.696-9.617-.037-21.309 7.974-27.784 8.01-6.474 19.64-7.738 29.224-3.96 9.583 3.78 17.013 12.191 20.478 21.896 1.996 5.582 2.672 12.097-.323 17.215-2.135 3.653-5.832 6.085-9.52 8.154-9.768 5.475-20.439 9.334-30.195 11.414h-.004z"
        fill="#851E33"
        stroke="#851E33"
      />
      <path
        d="M3.065 14.526c-2.066 1.734-1.915 5.126-1.577 7.98.607 5.149 1.264 10.462 3.708 14.993.86 1.595 2.535 3.252 4.027 2.462a34.693 34.693 0 003.59 12.663 8.269 8.269 0 003.498.11c1.386 9.164 9.488 16.796 18.046 16.991.787-7.125.823-14.367.107-21.555-.15-1.51-.952-3.528-2.34-3.263-.383.21-.677.595-.787 1.047.716-2.296.114-4.846-.518-7.22-.772-2.918-1.753-6.082-4.123-7.75-1.452-1.025-3.212-1.32-4.641-1.988-.603-2.826-2.752-4.957-4.785-6.886-2.866-2.723-5.743-5.453-8.914-7.742-.478-.346-.974-.684-1.529-.816-.463-.11-.94-.07-1.41-.03-1.173.103-2.349.206-2.349 1.011l-.003-.007z"
        fill="#288A51"
        stroke="#288A51"
      />
      <path
        d="M54.079 1.286C50.01 4.803 45.903 8.367 42.61 13.041 39.318 17.715 36.874 23.683 36.823 30c-1.176-.437-2.065 1.364-2.44 2.866-2.08 8.287-2.33 17.326-.71 25.785-1.74 1.61-1.778 4.887-1.535 7.636.485 5.497 5.075 12.405 6.36 17.682 6.27-5.75 7.287-15.746 8.669-25.95 4.218-5.633 6.89-13.155 7.445-20.953.213-2.983.051-6.257-1.445-8.55 2.12-1.558 2.734-5.097 2.955-8.235.514-7.346.048-14.805-2.043-18.99v-.004z"
        fill="#288A51"
        stroke="#288A51"
      />
      <path
        d="M41.111 80.914c-2.785-8.209 2.304-24.175 8.731-30.084.731-.672 1.525-1.315 2.025-2.168.503-.856.676-1.852.911-2.814 1.657-6.728 7.129-12.417 13.893-14.438 1.21-.36 2.466-.617 3.569-1.22 2.01-1.098 3.215-3.17 4.732-4.865 5.604-6.25 15.143-7.073 23.654-6.838.62 5.2-1.992 10.392-5.847 14.011-3.854 3.62-8.83 5.868-13.845 7.669-3.52 5.769-10.333 14.496-16.584 17.281-.867.386-1.826.809-2.215 1.658-.247.532-.221 1.139-.324 1.716-.154.87-.602 1.664-1.066 2.425-4.06 6.654-11.027 14.51-17.627 17.675l-.007-.008z"
        fill="#288A51"
        stroke="#288A51"
      />
      <path
        d="M7.742 22.488c18.035 22.58 34.681 47.932 28.254 75.77 8.889-31.065-8.793-54.27 16.646-86.157"
        stroke="#851E33"
        strokeLinecap="round"
      />
      <path
        d="M36.467 99.702c2.678-21.195 9.598-34.894 23.498-51.117 7.026-8.201 19.582-17.44 27.648-24.623"
        stroke="#851E33"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_112_80">
        <path fill="#fff" d="M0 0h100v154.623H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Carrot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={128} height={120} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#prefix__clip0_112_89)">
      <path
        d="M2.2 118.9a1.098 1.098 0 01-.845-1.803c.41-.49 41.164-49.262 54.21-69.645 2.397-3.814 8.603-8.257 13.002-8.257h.201c4.67.147 13.005 3.038 15.494 10.164 1.03 2.957 2.202 6.306 2.17 9.756-.015 1.72-.624 7.645-7.82 11.714l-.034.02c-20.78 10.78-45.011 27.134-64.48 40.275-3.968 2.677-7.716 5.206-11.286 7.591a1.082 1.082 0 01-.61.185H2.2z"
        fill="#FF7017"
      />
      <path
        d="M68.567 40.291h.166c3.595.113 12.022 2.357 14.489 9.426 1.062 3.042 2.136 6.162 2.108 9.383-.028 3.224-1.882 7.723-7.261 10.768-24.955 12.948-54.261 33.498-75.873 47.929 0 0 41.058-49.08 54.296-69.757 2.325-3.702 8.25-7.752 12.075-7.752m0-2.2c-3.01 0-6.184 1.785-7.805 2.85-2.596 1.7-4.826 3.859-6.127 5.923C41.623 67.194.92 115.9.512 116.39A2.2 2.2 0 002.2 120c.421 0 .849-.123 1.22-.371 3.572-2.385 7.32-4.917 11.29-7.594 19.446-13.125 43.65-29.46 64.371-40.213l.07-.038c2.805-1.586 4.97-3.638 6.44-6.102 1.575-2.636 1.92-5.02 1.936-6.557.035-3.642-1.168-7.089-2.23-10.13-1.26-3.607-3.991-6.567-7.9-8.556-3.198-1.628-6.535-2.278-8.597-2.344-.078 0-.154-.003-.232-.003v-.003z"
        fill="#FF7017"
      />
      <path
        d="M87.831 15.057c-1.857 3.067-2.24 6.73-2.84 10.24-1.182 6.91-3.35 13.652-6.109 21.357 9.266-9.879 19.133-20.144 32.286-24.336"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M97.512 28.813c.763-2.928 1.587-5.973 3.645-8.245a18.306 18.306 0 001.153 6.18c1.065-1.376 1.562-3.07 2.234-4.66.672-1.589 1.634-3.192 3.23-3.955l-.342 5.043a109.604 109.604 0 004.911-6.31 32.2 32.2 0 00-1.571 6.483c2.322-3.236 4.772-6.59 8.37-8.468.299-.157.72-.283.946-.031.198.22.075.553-.057.813a30.199 30.199 0 01-3.563 5.405"
        fill="#288A51"
      />
      <path
        d="M97.512 28.813c.763-2.928 1.587-5.973 3.645-8.245a18.306 18.306 0 001.153 6.18c1.065-1.376 1.562-3.07 2.234-4.66.672-1.589 1.634-3.192 3.23-3.955l-.342 5.043a109.604 109.604 0 004.911-6.31 32.2 32.2 0 00-1.571 6.483c2.322-3.236 4.772-6.59 8.37-8.468.299-.157.72-.283.946-.031.198.22.075.553-.057.813a30.199 30.199 0 01-3.563 5.405"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M113.091 21.385a60.971 60.971 0 0113.112-.075c-4.425 1.835-9.889-.883-14.284 1.012a25.283 25.283 0 018.436 3.506c-4.157-.17-8.27-.917-12.361-1.665a32.187 32.187 0 017.095 4.289l-12.182-1.829c2.278 1.273 4.556 2.548 6.834 3.82-4.047.02-8.1-.235-12.116-.753"
        fill="#288A51"
      />
      <path
        d="M113.091 21.385a60.971 60.971 0 0113.112-.075c-4.425 1.835-9.889-.883-14.284 1.012a25.283 25.283 0 018.436 3.506c-4.157-.17-8.27-.917-12.361-1.665a32.187 32.187 0 017.095 4.289l-12.182-1.829c2.278 1.273 4.556 2.548 6.834 3.82-4.047.02-8.1-.235-12.116-.753"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M84.821 27.135l-4.207-7.11c1.706 1.74 4.031 2.912 6.485 3.267a29.122 29.122 0 01-4.122-9.586c1.615 2.268 3.66 4.779 6.51 4.864-1.263-3.438-4.942-5.86-5.558-9.455 1.464 2.262 3.792 3.997 6.428 4.798-.568-4.402-4.96-8.044-4.446-12.443C88.861 4.57 90.464 8.82 90.254 13l-.506-.582"
        fill="#288A51"
      />
      <path
        d="M84.821 27.135l-4.207-7.11c1.706 1.74 4.031 2.912 6.485 3.267a29.122 29.122 0 01-4.122-9.586c1.615 2.268 3.66 4.779 6.51 4.864-1.263-3.438-4.942-5.86-5.558-9.455 1.464 2.262 3.792 3.997 6.428 4.798-.568-4.402-4.96-8.044-4.446-12.443C88.861 4.57 90.464 8.82 90.254 13l-.506-.582"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M88.362 15.245L95.142 1.1c.708 1.25.123 2.784-.508 4.06a65.987 65.987 0 01-5.103 8.602c1.33-2.919 4.67-4.854 7.987-4.628a95.26 95.26 0 01-11.406 8.993 26.574 26.574 0 018.585-3.708 46.226 46.226 0 00-9.96 9.194l8.712-4.676c-1.75 2.913-4.22 5.417-7.148 7.259-.911.572-1.97 1.231-2.074 2.278"
        fill="#288A51"
      />
      <path
        d="M88.362 15.245L95.142 1.1c.708 1.25.123 2.784-.508 4.06a65.987 65.987 0 01-5.103 8.602c1.33-2.919 4.67-4.854 7.987-4.628a95.26 95.26 0 01-11.406 8.993 26.574 26.574 0 018.585-3.708 46.226 46.226 0 00-9.96 9.194l8.712-4.676c-1.75 2.913-4.22 5.417-7.148 7.259-.911.572-1.97 1.231-2.074 2.278"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M78.882 46.654c4.519-2.058 9.094-4.135 14.07-5.166 5.016-1.037 10.367-.996 15.133-2.674"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.813 40.549a41.759 41.759 0 013.934-5.131 13.62 13.62 0 01-.06 3.73c.848-1.455 2.24-2.646 3.915-3.347a26.25 26.25 0 01-1.69 3.868 99.088 99.088 0 004.078-4.773l-1.763 4.33c1.848-1.772 3.695-3.544 5.54-5.32-.883 1.977-2.335 3.74-4.189 5.081a33.339 33.339 0 018.833-4.788c-1.926 1.712-3.874 3.44-6.187 4.735-1.401.782-3.246 1.392-4.701.619 4.095.298 8.057 2.196 10.618 5.087a182.245 182.245 0 00-11.774-4.459c2.746.713 5.034 2.407 7.227 4.057l-8.217-3.592c1.251 1.26 2.502 2.523 3.755 3.783l-6.416-4.044c.789 1.383 1.577 2.769 2.369 4.151-1.706-1.153-3.415-2.303-5.121-3.456-.173-.116-.384-.239-.585-.186-.204.057-.094.465.437-.352l-.003.007z"
        fill="#288A51"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.894 39.663a35.53 35.53 0 0112.65-.475c-3.817.874-7.732 1.449-12.65.475z"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_112_89">
        <path fill="#fff" d="M0 0h127.302v120H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const SmallCarrot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_333_6)">
      <path
        d="M0.799062 31.4585C0.722989 31.4585 0.648484 31.4252 0.59437 31.362C0.503395 31.2538 0.500258 31.0914 0.588096 30.9807C0.690834 30.8509 10.8627 17.9299 14.119 12.5299C14.7174 11.5193 16.2663 10.3423 17.3643 10.3423C17.3807 10.3423 17.3972 10.3423 17.4145 10.3423C18.5799 10.3814 20.6605 11.1472 21.2817 13.0352C21.5389 13.8185 21.8314 14.7059 21.8236 15.6199C21.8197 16.0752 21.6675 17.6452 19.8716 18.7232C19.8684 18.7249 19.8661 18.7265 19.8629 18.7282C14.6766 21.5843 8.62836 25.9171 3.76907 29.3983C2.77854 30.1075 1.84292 30.7776 0.951994 31.4094C0.905722 31.4427 0.852392 31.4585 0.799847 31.4585H0.799062Z"
        fill="#FF7017"
      />
      <path
        d="M17.3643 10.6328C17.3784 10.6328 17.3917 10.6328 17.4058 10.6328C18.303 10.6628 20.4064 11.2572 21.0221 13.1301C21.2871 13.9359 21.5554 14.7625 21.5483 15.6158C21.5412 16.4699 21.0785 17.6619 19.7359 18.4685C13.5073 21.899 6.19244 27.3431 0.798278 31.1664C0.798278 31.1664 11.0462 18.1638 14.3503 12.6856C14.9307 11.705 16.4098 10.632 17.3643 10.632M17.3643 10.0493C16.6129 10.0493 15.8208 10.5221 15.4162 10.8043C14.7684 11.2547 14.2115 11.8266 13.8868 12.3735C10.6392 17.7601 0.479867 30.6636 0.377913 30.7935C0.203022 31.0157 0.207728 31.3412 0.390461 31.5568C0.497905 31.6842 0.647699 31.7499 0.799062 31.7499C0.904153 31.7499 1.01081 31.7175 1.10336 31.6517C1.99506 31.0199 2.93069 30.349 3.92121 29.6397C8.77502 26.1627 14.8162 21.8349 19.9884 18.9863C19.9939 18.983 20.0002 18.9796 20.0057 18.9763C20.706 18.5559 21.2464 18.0123 21.6134 17.3597C22.0063 16.6613 22.0926 16.0295 22.0965 15.6224C22.1051 14.6577 21.8048 13.7445 21.5397 12.9387C21.2252 11.9831 20.5437 11.1989 19.568 10.672C18.7697 10.2408 17.9368 10.0685 17.4223 10.051C17.4027 10.051 17.3839 10.0501 17.3643 10.0501V10.0493Z"
        fill="#FF7017"
      />
      <path
        d="M22.1726 3.94775C21.7091 4.76021 21.6134 5.73082 21.4636 6.66064C21.1687 8.49116 20.6276 10.2776 19.939 12.3187C22.2518 9.70151 24.7144 6.98196 27.9973 5.8715"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M24.5889 7.59215C24.7795 6.81632 24.9849 6.0097 25.4986 5.40785C25.5041 5.96391 25.6022 6.51998 25.7865 7.04524C26.0523 6.68064 26.1762 6.23196 26.3441 5.81075C26.5119 5.38954 26.7519 4.965 27.1503 4.76272C27.1221 5.20807 27.093 5.65342 27.0648 6.09877C27.493 5.55686 27.9016 4.99996 28.2906 4.42725C28.104 4.98414 27.973 5.56019 27.8985 6.14455C28.4781 5.28715 29.0898 4.39895 29.9878 3.90115C30.0623 3.85953 30.1674 3.82623 30.2238 3.89283C30.2732 3.9511 30.2426 4.03933 30.2097 4.10843C29.9634 4.61621 29.6646 5.09735 29.3204 5.54021"
        fill="#288A51"
      />
      <path
        d="M24.5889 7.59215C24.7795 6.81632 24.9849 6.0097 25.4986 5.40785C25.5041 5.96391 25.6022 6.51998 25.7865 7.04524C26.0523 6.68064 26.1762 6.23196 26.3441 5.81075C26.5119 5.38954 26.7519 4.965 27.1503 4.76272C27.1221 5.20807 27.093 5.65342 27.0648 6.09877C27.493 5.55686 27.9016 4.99996 28.2906 4.42725C28.104 4.98414 27.973 5.56019 27.8985 6.14455C28.4781 5.28715 29.0898 4.39895 29.9878 3.90115C30.0623 3.85953 30.1674 3.82623 30.2238 3.89283C30.2732 3.9511 30.2426 4.03933 30.2097 4.10843C29.9634 4.61621 29.6646 5.09735 29.3204 5.54021"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M28.4773 5.62441C29.5619 5.49289 30.6614 5.48623 31.75 5.60443C30.6458 6.09057 29.2819 5.37052 28.1847 5.87248C28.9345 6.04396 29.6529 6.36111 30.2905 6.80147C29.2529 6.75652 28.2263 6.5584 27.2052 6.36028C27.8405 6.65746 28.4381 7.04038 28.9761 7.49655C27.9628 7.33506 26.9487 7.17357 25.9355 7.01207C26.5041 7.34921 27.0727 7.68717 27.6412 8.02431C26.6311 8.0293 25.6194 7.96188 24.6171 7.82453"
        fill="#288A51"
      />
      <path
        d="M28.4773 5.62441C29.5619 5.49289 30.6614 5.48623 31.75 5.60443C30.6458 6.09057 29.2819 5.37052 28.1847 5.87248C28.9345 6.04396 29.6529 6.36111 30.2905 6.80147C29.2529 6.75652 28.2263 6.5584 27.2052 6.36028C27.8405 6.65746 28.4381 7.04038 28.9761 7.49655C27.9628 7.33506 26.9487 7.17357 25.9355 7.01207C26.5041 7.34921 27.0727 7.68717 27.6412 8.02431C26.6311 8.0293 25.6194 7.96188 24.6171 7.82453"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.4213 7.14744C21.0715 6.51978 20.7209 5.8913 20.3711 5.26365C20.797 5.72481 21.3773 6.03531 21.9898 6.12937C21.5044 5.35188 21.1538 4.48782 20.9609 3.58963C21.364 4.19064 21.8746 4.85576 22.5859 4.87823C22.2706 3.96755 21.3522 3.32575 21.1985 2.37345C21.564 2.9728 22.1451 3.4323 22.8031 3.64457C22.6612 2.47833 21.5648 1.51355 21.6934 0.348145C22.4298 1.16892 22.8298 2.2952 22.7772 3.40233C22.7349 3.35072 22.6933 3.29994 22.651 3.24833"
        fill="#288A51"
      />
      <path
        d="M21.4213 7.14744C21.0715 6.51978 20.7209 5.8913 20.3711 5.26365C20.797 5.72481 21.3773 6.03531 21.9898 6.12937C21.5044 5.35188 21.1538 4.48782 20.9609 3.58963C21.364 4.19064 21.8746 4.85576 22.5859 4.87823C22.2706 3.96755 21.3522 3.32575 21.1985 2.37345C21.564 2.9728 22.1451 3.4323 22.8031 3.64457C22.6612 2.47833 21.5648 1.51355 21.6934 0.348145C22.4298 1.16892 22.8298 2.2952 22.7772 3.40233C22.7349 3.35072 22.6933 3.29994 22.651 3.24833"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M22.3051 3.9976C22.869 2.74812 23.4337 1.49865 23.9976 0.25C24.174 0.581307 24.0281 0.987533 23.8705 1.3255C23.5019 2.11631 23.0761 2.87882 22.5969 3.60469C22.9286 2.83137 23.7623 2.31859 24.5905 2.37852C23.7035 3.24592 22.7506 4.04255 21.7436 4.76094C22.3906 4.30477 23.1208 3.97013 23.8862 3.77867C22.9459 4.4596 22.1051 5.28371 21.4001 6.21436C22.1247 5.80148 22.8502 5.38859 23.5748 4.97571C23.138 5.74737 22.5216 6.41082 21.7906 6.89862C21.5632 7.05012 21.2989 7.22493 21.273 7.50213"
        fill="#288A51"
      />
      <path
        d="M22.3051 3.9976C22.869 2.74812 23.4337 1.49865 23.9976 0.25C24.174 0.581307 24.0281 0.987533 23.8705 1.3255C23.5019 2.11631 23.0761 2.87882 22.5969 3.60469C22.9286 2.83137 23.7623 2.31859 24.5905 2.37852C23.7035 3.24592 22.7506 4.04255 21.7436 4.76094C22.3906 4.30477 23.1208 3.97013 23.8862 3.77867C22.9459 4.4596 22.1051 5.28371 21.4001 6.21436C22.1247 5.80148 22.8502 5.38859 23.5748 4.97571C23.138 5.74737 22.5216 6.41082 21.7906 6.89862C21.5632 7.05012 21.2989 7.22493 21.273 7.50213"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M19.939 12.3186C21.0668 11.7734 22.2087 11.2231 23.4509 10.9501C24.7026 10.6754 26.0382 10.6862 27.2279 10.2417"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9136 10.7012C25.2045 10.2258 25.5331 9.77051 25.8955 9.34181C25.9331 9.67145 25.9284 10.0036 25.8806 10.3299C26.0923 9.94448 26.4398 9.62899 26.8578 9.44336C26.744 9.79465 26.6029 10.1376 26.4358 10.4681C26.7888 10.0569 27.1283 9.63482 27.4538 9.20362C27.3071 9.58571 27.1605 9.96862 27.0138 10.3507C27.475 9.88122 27.9361 9.41173 28.3965 8.94141C28.1761 9.465 27.8138 9.932 27.3511 10.2874C28.0059 9.75802 28.7525 9.32765 29.5556 9.01882C29.0749 9.4725 28.5886 9.93033 28.0114 10.2733C27.6616 10.4806 27.2013 10.6421 26.8382 10.4373C27.8601 10.5164 28.849 11.0192 29.4882 11.785C28.5243 11.3563 27.544 10.9625 26.5496 10.6038C27.235 10.7927 27.8059 11.2414 28.3534 11.6784C27.6695 11.3613 26.9864 11.0441 26.3025 10.727C26.6146 11.0608 26.9268 11.3954 27.2397 11.7292C26.7056 11.3721 26.1723 11.015 25.6382 10.6579C25.8351 11.0241 26.0319 11.3912 26.2296 11.7575C25.8037 11.452 25.3771 11.1473 24.9512 10.8418C24.9081 10.811 24.8555 10.7786 24.8054 10.7927C24.7544 10.8077 24.7818 10.9159 24.9144 10.6995L24.9136 10.7012Z"
        fill="#288A51"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9291 10.4664C28.951 10.2258 30.0301 10.1809 31.0865 10.3407C30.1336 10.5721 29.1564 10.7244 27.9291 10.4664Z"
        stroke="#288A51"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_333_6">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Leek = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={144} height={140} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#prefix__clip0_112_106)" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M32.187 16.112A522.444 522.444 0 0124.138.996 121.562 121.562 0 014.894 8.4c-1.295.376-2.595.729-3.898 1.085 1.493 4.168 4.35 7.679 7.158 11.1 1.704 2.08 3.414 4.163 5.357 6.017 1.782 1.7 3.747 3.197 5.497 4.928 3.693 3.656 6.32 8.26 9.72 12.397a65.49 65.49 0 0118.584 14.873c2.444-1.99 4.888-3.98 7.332-5.973.327-.265.668-.552.799-.951.128-.384.04-.803-.057-1.196-2.037-8.262-7.275-15.306-12.393-22.102-2.513-3.337-5.05-6.7-8.177-9.47-1.099-.974-2.066-1.973-2.632-2.992l.003-.003z"
        fill="#35A664"
        stroke="#35A664"
        strokeWidth={7}
      />
      <path
        d="M15.122 11.801l32.477 38.497c-7.104-13.453-15.412-26.245-23.043-39.402-.984-2.662 1.078-3.983 3.224-4.444l9.093-2.546C49.056 17.894 59.082 34.3 66.564 51.796c22.29 27.255 47.574 51.974 75.994 73.718 3.67 4.484-1.625 9.187-5.096 11.773-1.38.842-3.456 3.635-5.67 2.135C89.829 111.007 44.02 62.344 1.186 22.968a51.225 51.225 0 006.607-6.375c.569-.658 1.132-1.35 1.402-2.178.111-.344.174-.435.347-.754.513-.929 5.58-1.86 5.58-1.86z"
        fill="url(#prefix__paint0_linear_112_106)"
        stroke="#288A51"
      />
    </g>
    <defs>
      <linearGradient
        id="prefix__paint0_linear_112_106"
        x1={58.365}
        y1={52.797}
        x2={140.01}
        y2={134.387}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#288A51" />
        <stop offset={0.02} stopColor="#318F58" />
        <stop offset={0.16} stopColor="#66AC80" />
        <stop offset={0.3} stopColor="#93C5A3" />
        <stop offset={0.45} stopColor="#B7DABF" />
        <stop offset={0.59} stopColor="#D4EAD5" />
        <stop offset={0.72} stopColor="#E8F5E5" />
        <stop offset={0.86} stopColor="#F4FCEE" />
        <stop offset={1} stopColor="#F9FFF2" />
      </linearGradient>
      <clipPath id="prefix__clip0_112_106">
        <path fill="#fff" transform="matrix(-1 0 0 1 143.944 0)" d="M0 0h143.944v140H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Sweetcorn = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={100} height={140} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#prefix__clip0_112_139)">
      <path
        d="M77.692 9.297c4.292-2.08 10.936-3.57 15.132-5.835 3.041 2.493-.772 5.57 3.31 7.481 1.006.47 1.63 4.725 1.365 5.063-2.93 3.78-5.158 4.515-5.9 6.636-.446 1.27-5.753-2.204-7.952-.603"
        fill="#288A51"
      />
      <path
        d="M77.692 9.297c4.292-2.08 10.936-3.57 15.132-5.835 3.041 2.493-.772 5.57 3.31 7.481 1.006.47 1.63 4.725 1.365 5.063-2.93 3.78-5.158 4.515-5.9 6.636-.446 1.27-5.753-2.204-7.952-.603"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M5.377 133.053c-4.428-2.81-3.438-9.842-1.758-15.297 11.496-37.336 35.557-67.85 61.414-94.198 4.873-4.964 9.882-9.866 15.651-13.357 1.948-1.18 4.21-2.216 6.314-1.457.949.342 1.774 1.027 2.579 1.7l5.203 4.345c1.7 1.42 3.57 3.202 3.578 5.608 0 1.246-.516 2.406-1.028 3.503-7.555 16.139-17.285 33.053-28.287 46.353-5.768 6.973-10.303 13.786-15.77 21.07-32.595 43.397-34.23 47.449-40.96 45.266-1.217-.396-.606.475-6.928-3.536h-.008z"
        fill="#F7D200"
        stroke="#F7D200"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M2.36 117.962C12.5 69.015 40.637 40.13 82.426.619c3.053 10.188-33.297 46.352-38.892 55.484C29.442 79.1 29.203 79.748 24.06 105.175c-5.401 26.722-8.488 40.62-22.522 24.585-2.137-2.443-.012-7.807.817-11.798h.004z"
        fill="#288A51"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M.784 128.072c.458 3.532 11.839 11.905 15.792 10.688 14.323-4.416 16.468-8.274 23.55-15.45 7.019-7.114 20.78-32.904 26.512-41.26C77.758 65.825 82.02 54.762 99.38 29.434c-7.444-.83-26.681 30.486-26.842 30.725-26.471 38.611-33.977 54.127-45.007 60.477-12.858 7.403-27.033-1.39-26.744 7.436H.784z"
        fill="#288A51"
        stroke="#288A51"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_112_139">
        <path fill="#fff" d="M0 0h100v139.494H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const RedPepper = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={100} height={141} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#prefix__clip0_112_134)" strokeWidth={2}>
      <path
        d="M48.995 33.994c-8.534-4.448-37.88 3.981-44.67 12.582-9.497 12.025 2.118 34.213 7.743 54.581 3.086 11.173 1.257 33.808 19.325 37.399 7.92 1.572 14.283 1.462 20.831-6.372 5.096 1.553 27.146 1.848 36.899-3.796 16.492-9.539 8.867-24.454 4.943-47.38-3.853-22.526 4.63-60.692-18.55-57.725-19.063 2.438-15.92 2.586-26.526 10.72l.005-.01z"
        fill="#C1272D"
        stroke="#C1272D"
        strokeMiterlimit={10}
      />
      <path
        d="M20.726 41.18c-5.93 4.186-7.587 5.653-11.625 8.9 8.367-3.97 18.15-8.343 27.407-8.586-1.152 2.33 1.715 11.94 1.715 11.94S42.37 41.07 53.338 36.132c16.393-7.377 22.036-8.567 22.036-8.567s-12.316-2.21-29.936 5.805c-4.01-9.849.457-22.64 7.296-30.784-4.01-2.338-10.144.538-10.144.538s-7.144 8.996-4.7 21.14c.438 2.167.333 4.353-.248 6.486-3.124 11.473-1.62.243-16.916 10.435v-.005z"
        fill="#288A51"
        stroke="#288A51"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_112_134">
        <path fill="#fff" d="M0 0h100v140.061H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Onion = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={91} height={120} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#prefix__clip0_112_111)">
      <path
        d="M30.963 10.59c-.233.738-.06 6.582-.478 5.933-1.554-2.413-5.213-11.421-6.392-13.393.359 1.556.743 6.955.925 9.78a.527.527 0 01-.942.36c-1.589-2.044-4.627-5.695-6.528-6.37 1.339 4.504 2.307 6.472 3.443 10.297 1.217 4.093-1.483 6.82-2.252 7.715C9.938 35.15 3.321 42.067 1.775 63.764-.908 84.29 15.1 103.685 35.205 108.308c4.601 1.132 7.889 2.578 8.679 4.851 1.428 1.48-1.162 6.582.207 5.081.245-.267 4.267-4.054 4.63-4.071.242-.013 2.578 2.281 2.82 2.307.861.085.286-1.569.743-2.303 1.2-1.938 3.883-.348 6.114.072.38-1.026-2.476-4.262 2.463-7.634 31.343-21.4 31.757-44.124 25.453-56.804C70.31 17.613 45.988 28.11 37.56 11.154c-1.703-3.427-1.534-9.131-2.07-9.644-.512-.488-3.736 6.552-4.526 9.08z"
        fill="#E69D5E"
        stroke="#E69D5E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_112_111">
        <path fill="#fff" d="M0 0h91v120H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const LoadingSpinner = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 100 100"
    fill="none"
    className="animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M50 0C22.4 0 0 22.4 0 50C0 77.6 22.4 100 50 100C77.6 100 100 77.6 100 50C100 22.4 77.6 0 50 0ZM50 90C27.6 90 10 72.4 10 50C10 27.6 27.6 10 50 10C72.4 10 90 27.6 90"
      fill={theme.extend.colors.sweetcorn || "#FFC940"}
    />
  </svg>
);

export const getRandomVeggie = (index = 0) => {
  switch (index) {
    case 0:
      return <Carrot className="" aria-hidden="true" />;
    case 1:
      return <Beetroot className="" aria-hidden="true" />;
    case 2:
      return <Leek className="" aria-hidden="true" />;
    case 3:
      return <Sweetcorn className="" aria-hidden="true" />;
    case 4:
      return <RedPepper className="" aria-hidden="true" />;
    case 5:
      return <Onion className="" aria-hidden="true" />;
  }
};
